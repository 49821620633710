import React, {Component} from 'react';
import DataTable from '../data-table/DataTable';
import moment from 'moment';
import moneyFormat from '../../helpers/MoneyFormat'
import config from '../../config'
import {Button} from "reactstrap";
import ShowDocument from '../../components/show-document/ShowDocument'
import excelIcon from '../../theme/assets/ico-excel.png';
import TransferenceType from '../../services/TransferenceType';

import { getTransferenceStatusFromId, TransferenceDocumentType } from '@autofact-lib/operation-lib-model-notary-platform';


class BankTransactionsList extends Component {
    constructor(props) {
        super(props);
        this.dataTableRef = React.createRef();
        this.state ={
            showDocument:false,
            documentUrl:null
        }

    }

    reloadData() {
        this.dataTableRef.current.reloadData();
    }

    renderPlate(item) {
        if(item.isClosure){
            return null;
        }else if(item.details && item.details.patente && item.transfer && item.transfer.code){
            return <a className="text-primary-dark" href={`${config.endpoints.autofactProApi.base}transferencia/ver/${item.transfer.code}`} >{item.details.patente}</a>
        }else if(item.transfer && item.transfer.code && item.transfer.plate){
            return <a className="text-primary-dark" href={`${config.endpoints.autofactProApi.base}transferencia/ver/${item.transfer.code}`} >{item.transfer.plate}</a>
        }else if(item.details && item.details.patente){
            return item.details.patente;
        }else{
            return '-'
        }
    }
    renderTransferType(item) {
        if(item.isClosure){
            return null;
        } else if(item.details && item.details.tipo_contrato){
            return item.details.tipo_contrato
        } else if(item.transfer && item.transfer.type != null) {
            return TransferenceType.codeToString(item.transfer.type);
        } else{
            return '-'
        }
    }
    renderBalance(item){
        if (item.isClosure) {
            return null;
        } else if (item.details) {
            if (item.account_origin === this.props.accountSelected.id && item.details.saldo_cuenta_origen) {
                return <span>${moneyFormat(item.details.saldo_cuenta_origen)}</span>;
            } else if (item.account_destino === this.props.accountSelected.id && item.details.saldo_cuenta_destino) {
                return <span>${moneyFormat(item.details.saldo_cuenta_destino)}</span>;
            }
        }
        return '-';
    }

    renderDate(item) {
        const _date = moment.utc(item.timestamp);
        if(_date.isValid()){
            return <span className="table-text">
                <span style={{fontWeight:'bold'}}>{_date.format('DD-MM-YYYY')}</span>&nbsp;
                <span>{_date.format('HH:mm:ss')}</span>
            </span>
        }
    }

    renderAmmount(item){
        return  <span>${moneyFormat(item.ammount)}</span>;
    }
    showDocumentModal(documentUrl){
        this.setState({showDocument:true, documentUrl: documentUrl});
    }
    closeDialog = ()=>{
        this.setState({showDocument:false})
    }
    renderPayTax(item){
        let url = null;
        if(item.transfer && item.transfer.transfer_documents) {
            item.transfer.transfer_documents.forEach(transfer_document=> {
                if (transfer_document.type === TransferenceDocumentType.TAX_DOCUMENT_PROOF.id) {
                    url = transfer_document.document.path
                }
            })
        }
        if(url){
            return <Button className="btn btn-sm btn-outline-detail" outline color="primary" onClick={(e)=>{this.showDocumentModal(url)}}>Ver</Button>
        }else{
            return <span className="text-gray">No Disponible</span>
        }
    }
    renderRequest(item){
        let url = null;
        if(item.isClosure){
            return <a className="btn btn-sm btn-outline-detail" href={item.details_url} color="primary">
                <img src={excelIcon} alt="Excel icon"/> &nbsp;
                Exportar a Excel</a>
        }
        if(item.transfer && item.transfer.transfer_documents){
            item.transfer.transfer_documents.forEach(transfer_document=>{
                if(transfer_document.type === TransferenceDocumentType.TRANSFER_REQUEST.id){
                    url = transfer_document.document.path
                }
            })
        }

        if(url){
            return <Button outline className="btn btn-sm btn-outline-detail" color="primary" onClick={(e)=>{this.showDocumentModal(url)}}>Ver</Button>
        }else{
            return <span className="text-gray">No Disponible</span>
        }
    }
    renderStatus(item){
        if (item.isClosure) {
            return null;
        } else if (item.transfer && item.transfer.status) {
            return <span key={`status-${item.transfer.id}`}
                         className={`transference-status transference-status-sm transference-status-${item.transfer.status}`}>
                            {getTransferenceStatusFromId(item.transfer.status).label}
                        </span>
        } else {
            return '-'
        }
    }

    render() {
        const columns = [
            {
                Header: 'Exportar a Excel',
                accessor: 'tipo_documento_pago',
                headerClassName:'wordwrap header-opacity',
                width:70,
                Cell: row => {
                    if(row.original.isClosure){
                        return null
                    }else{
                        return <label className="custom-check">
                            <input
                                type="checkbox"
                                checked={typeof this.props.selecteds[row.original.id] !== 'undefined'}
                                onClick={(e)=>this.props.selectedRow(e, row.original)}/>
                                <span className="checkmark"></span>
                        </label>
                    }
                }
            },
            {
                Header: 'Fecha transacción',
                accessor: 'timestamp',
                className: `table-text text-center`,
                width:100,
                headerClassName:'wordwrap',
                Cell: row => {
                    return this.renderDate(row.original)
                }
            },{
                Header: 'Patente',
                accessor: 'details',
                headerClassName:'wordwrap',
                width:70,
                Cell: row => {
                    return this.renderPlate(row.original)
                }
            },
            {
                Header: 'Tipo contrato',
                accessor: 'details',
                width:70,
                headerClassName:'wordwrap',
                Cell: row => {
                    return this.renderTransferType(row.original)
                }
            },
            {
                Header: 'Estado',
                accessor: 'transfer',
                headerClassName:'wordwrap',
                Cell: row => {
                    return this.renderStatus(row.original)
                }
            },
            {
                Header: 'Cargo',
                accessor: 'ammount',
                width:70,
                headerClassName:'wordwrap',
                Cell: row => {
                    if(row.original.isClosure){
                        return null;
                    }else if(row.original.account_origin === this.props.accountSelected.id)
                        return this.renderAmmount(row.original)
                    return '-'
                }
            },
            {
                Header: 'Abono',
                accessor: 'ammount',
                width:70,
                headerClassName:'wordwrap',
                Cell: row => {
                   if(row.original.isClosure || row.original.account_destino === this.props.accountSelected.id)
                         return this.renderAmmount(row.original)
                    return '-'
                }
            },
            {
                Header: 'Saldo',
                accessor: 'ammount',
                width:70,
                headerClassName:'wordwrap',
                Cell: row => {
                   return this.renderBalance(row.original);
                }
            },
            {
                Header: 'Descripción',
                accessor: 'concept',
                headerClassName:'wordwrap',
                Cell: row=>{
                    if(row.original.isClosure){
                        return `CIERRE(${row.original.correlative})`;
                    }
                    return <div style={{whiteSpace: 'normal'}}>{row.original.concept}</div>;
                }
            },
            {
                Header: 'Comprobante pago Impuesto',
                accessor: 'estado',
                headerClassName:'wordwrap',
                Cell: row => {
                    if(row.original.isClosure){
                        return null;
                    }
                    return this.renderPayTax(row.original)
                }
            },
            {
                Header: 'Solicitud',
                accessor: 'total',
                Cell: row => {
                    return this.renderRequest(row.original)

                }
            },
        ];

        return (
            <div className={`bank-transactions-list-wrapper tab-pane`}>
                {this.state.showDocument && <ShowDocument
                    src={this.state.documentUrl}
                    closeDialog={this.closeDialog}
                />}
                <DataTable
                    ref={this.dataTableRef}
                    key={`data-table-${this.props.id}`}
                    id={this.props.id}
                    columns={columns}
                    getTrProps={(state, rowInfo, column) => {
                        return {
                          style: {
                            background: rowInfo && rowInfo.original.isClosure ? "#FEE2AE" : null
                          }
                        };
                      }}
                    {...this.props}
                />
            </div>
        );
    }
}

export default BankTransactionsList;
