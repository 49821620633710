import React, {Component} from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

import {endpoints} from '../../config';
import AuthService from "../../services/AuthService";
import PrivateRoute from '../../components/private-route/PrivateRoute';
import PropsRoute from '../../components/props-route/PropsRoute';
import {MainLayout} from '../../theme/index';

import { Permissions } from '@autofact-lib/operation-lib-model-notary-platform';

import Home from '../../pages/home/Home';
import Login from '../../pages/login/Login';
import Logout from '../../pages/logout/Logout';
import NotFound from '../../pages/not-found/NotFound';
import Forbidden from '../../pages/forbidden/Forbidden';
import InvoiceCenterClosureList from '../../pages/invoice-center-closures-list/InvoiceCenterClosureList';
import InvoiceCenterBillingList from '../../pages/invoice-center-billing-list/InvoiceCenterBillingList';
import SaleNotesList from '../../pages/sale-notes-list/SaleNotesList';
import InvoiceCenterBilling from '../../pages/invoice_center_billing/InvoiceCenterBilling';
import PaymentSuccess from '../../pages/payment-success/PaymentSuccess';
import PaymentError from '../../pages/payment-error/PaymentError';
import Payment from '../../pages/payment/Payment';
import InvoiceCenterContactList from '../../pages/invoice-center-contact-list/InvoiceCenterContactList';

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoggedIn: false
        };

        this.setIsAuthenticated = this.setIsAuthenticated.bind(this);
    }

    componentWillMount() {
        AuthService.getAuthData().then(authData => {
            // google tag manager
            window.dataLayer.push({ 'userId' : authData.afpr_us_user_id });
            if (authData) {
                this.setIsAuthenticated(true);
            }
            else {
                this.setIsAuthenticated(false);
            }
        });
    }

    setIsAuthenticated(isLoggedIn) {
        this.setState({isLoggedIn});
    }

    render() {
        return (
            <BrowserRouter>
                <MainLayout isLoggedIn={this.state.isLoggedIn}>
                    <Switch>
                        <Route path="/" exact component={Home}/>
                        <Route path="/cierres-antiguos" exact component={InvoiceCenterClosureList}/>
                        <Route path="/cierres" exact component={SaleNotesList}/>
                        <Route path="/facturas" exact component={InvoiceCenterBillingList}/>
                        <Route path="/facturas/pago-exitoso" exact component={PaymentSuccess}/>
                        <Route path="/facturas/pago-error" exact component={PaymentError}/>
                        <Route path="/facturas/pago" exact component={Payment}/>
                        <PrivateRoute isLoggedIn={this.state.isLoggedIn} permission={Permissions.COLLECTION_SEE_PROVISION_CLOSURE} path="/banco" exact component={InvoiceCenterBilling}/>
                        <PropsRoute path="/login" exact component={Login} setIsAuthenticated={this.setIsAuthenticated}/>
                        <Route path='/external-login' component={() => window.location = endpoints.autofactProApi.url}/>
                        <PropsRoute path="/logout" exact component={Logout} setIsAuthenticated={this.setIsAuthenticated}/>
                        <Route path="/403" exact component={Forbidden}/>
                        <Route path="/contacto-facturacion" exact component={InvoiceCenterContactList}/>
                        <PrivateRoute isLoggedIn={this.state.isLoggedIn} path="*" component={NotFound}/>
                    </Switch>
                </MainLayout>
            </BrowserRouter>
        );
    }
}

export default App;
