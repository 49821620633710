import React, { Component } from "react";
import { connect } from "react-redux";
import { Col } from 'reactstrap';
import { Permissions } from '@autofact-lib/operation-lib-model-notary-platform';
import AuthService from '../../services/AuthService';
import BackendApiService from '../../services/BackendApiService';
import { changeBillingCount, changeClosureCount, changeSaleNotesCount } from '../../store/dispatchers/app';
import { buildBillingFilters, buildClosureFilters, buildSaleNoteFilters } from '../../helpers/filterBuilder';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { NavLink as RRNavLink, withRouter, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Footer from '../../components/footer/Footer';
import logo from "../assets/logo_af_pro.svg";

class MainLayout extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.state = {
      isOpen: false,
      dropdownOpen: false,
      loadedTabTotals: false,
    };
  }

  loadTabTotals() {

    if (this.props.userData.afpr_us_logged_in && this.state.loadedTabTotals === false) {
      console.log('loading totals from main layout');
      if (this.props.location.pathname !== '/facturas') {
        BackendApiService.getPaymentDocuments(buildBillingFilters(this.props.userData)).then(data => {
          if (data.extra && data.extra.recordsTotal > 0){
            changeBillingCount(data.extra.recordsTotal);
          }
        }).catch(console.error);
      }

      if (this.props.location.pathname !== '/cierres-antiguos') {
        BackendApiService.getInvoiceCenterClosureList(buildClosureFilters(this.props.userData)).then(data => {
          if (data.extra && data.extra.recordsTotal > 0){
            changeClosureCount(data.extra.recordsTotal)
          }
        }).catch(console.error);
      }

      if (this.props.location.pathname !== '/cierres') {
        BackendApiService.getPaymentDocuments(buildSaleNoteFilters(this.props.userData)).then(data => {
          if (data.extra && data.extra.recordsTotal > 0){
            changeSaleNotesCount(data.extra.recordsTotal);
          }
        }).catch(console.error);
      }
      this.setState({
        loadedTabTotals: true,
      });

    }
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  toggleDropdown() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  render() {
    const loginInfo = this.props.userData || {};
    const userData = loginInfo.usuario || {};
    const user = userData || {};
    const invoiceCenter = user.centro_facturacion || {};
    const invoiceCenterData = invoiceCenter || {};
    const invoiceCenterName = invoiceCenterData.nombre_corto || "-";
    this.loadTabTotals();
    return (
      <div className="wrapper">
        <Navbar color="dark" dark expand="md" className={"bg-primary-darker"}>
          <NavbarBrand to="/" tag={RRNavLink}>
            <img src={logo} alt="Autofact Pro Logo" style={{width:200, height:50}}/>
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <div className="ml-auto">
              <Nav navbar>
                <div className="navbar-menu">
                  <NavItem>
                    <NavLink
                      to="/facturas"
                      activeClassName="active"
                      tag={RRNavLink}
                    >
                      Facturas ({this.props.billingCount})
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      to="/cierres"
                      activeClassName="active"
                      tag={RRNavLink}
                    >
                      Cierres ({this.props.saleNotesCount})
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      to="/cierres-antiguos"
                      activeClassName="active"
                      tag={RRNavLink}
                    >
                      Cierres Antiguos ({this.props.closureCount})
                    </NavLink>
                  </NavItem>
                  {AuthService.canDo(Permissions.COLLECTION_SEE_PROVISION_CLOSURE) && <NavItem>
                    <NavLink
                      to="/banco"
                      activeClassName="active"
                      tag={RRNavLink}
                    >
                      Banco
                    </NavLink>
                  </NavItem>


                  }
                  <NavItem>
                    <NavLink
                      to="/contacto-facturacion"
                      activeClassName="active"
                      tag={RRNavLink}
                    >
                      Datos facturación
                    </NavLink>
                  </NavItem>
                </div>
                <div className="navbar-session">
                  {
                    this.props.isLoggedIn ?
                      <NavItem className="navbar-emp text-center">
                        <NavLink>
                          <FontAwesomeIcon icon="building" />
                          <span className="emp-name">{invoiceCenterName}</span>
                        </NavLink>
                        <Col md={12} className="text-white pl-2 pr-2">
                          {user.mail || 'Desconocido'}
                        </Col>
                      </NavItem>
                      :
                      <span></span>
                  }
                  <NavItem>
                    <Dropdown
                      isOpen={this.state.dropdownOpen}
                      toggle={this.toggleDropdown}
                    >
                      <DropdownToggle caret nav>
                        <FontAwesomeIcon icon="user" />
                      </DropdownToggle>
                      <DropdownMenu right>
                        {this.props.isLoggedIn ? (
                          <div>
                            <DropdownItem>
                              <Link to="/logout" className="dropdown-link">
                                <FontAwesomeIcon icon="sign-out-alt" />
                                Cerrar Sesión
                              </Link>
                            </DropdownItem>
                          </div>
                        ) : (
                            <DropdownItem>
                              <Link to="/login" className="dropdown-link">
                                <FontAwesomeIcon icon="sign-in-alt" />
                              Iniciar Sesión
                            </Link>
                            </DropdownItem>
                          )}
                      </DropdownMenu>
                    </Dropdown>
                  </NavItem>
                </div>
              </Nav>
            </div>
          </Collapse>
        </Navbar>
        <div className={"main-container"}>{this.props.children}</div>
        <Footer />
      </div>
    );
  }
}
export default withRouter(
  connect(store => ({
    billingCount: store.app.billingCount,
    saleNotesCount: store.app.saleNotesCount,
    closureCount: store.app.closureCount,
    userData: store.app.userData
  }))(MainLayout)
);
